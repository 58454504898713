class LazyVideo extends HTMLElement {
  constructor() {
    super();
    this.observer = null;
  }

  connectedCallback() {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.querySelectorAll("source").forEach((source) => {
              source.src = source.dataset.src;
            });
            this.querySelector("video").load();
            observer.unobserve(this);
          }
        });
      },
      { rootMargin: "240px" },
    );

    this.observer.observe(this);
  }

  disconnectedCallback() {
    this.observer.unobserve(this);
  }
}

customElements.define("lazy-video", LazyVideo);
